<template>
  <div class="bi">
    <iframe title="bi" width="100"
            height="100"
            v-if="uri"
            :src="uri"
            class="bi__frame"
            allowFullScreen="true">
    </iframe>

    <div class="bi__forbidden" v-if="!uri">
      Доступ запрещен
    </div>

  </div>
</template>

<script>
import {
  ROLE_HEAD_DOCTOR,
  ROLE_HEAD_DEPARTMENT,
  ROLE_OFFICE,
  ROLE_DOCTOR_EMPLOYEE,
  ROLE_ADMIN_EMPLOYEE
} from '../models/AuthUser'
import api from '../utils/api'

export default {
  data () {
    return {
      powerbi: null,
      uri: false
    }
  },
  async beforeMount () {
    let resp = await api.get('/api/v1/bi/report-urls')

    this.powerbi = resp.records

    const roles = this.$store.state.app.currentUser.roles
    const isHeadDoctor = roles.indexOf(ROLE_HEAD_DOCTOR) > -1
    const isDoctor = roles.indexOf(ROLE_DOCTOR_EMPLOYEE) > -1
    const isAdmin = roles.indexOf(ROLE_ADMIN_EMPLOYEE) > -1
    const isDepartmentHead = roles.indexOf(ROLE_HEAD_DEPARTMENT) > -1
    const isOffice = roles.indexOf(ROLE_OFFICE) > -1

    if (isOffice) this.uri = this.powerbi[3].fields.URL
    if (isHeadDoctor) this.uri = this.powerbi[1].fields.URL
    if (isDepartmentHead) this.uri = this.powerbi[2].fields.URL
    if (isDoctor) this.uri = this.powerbi[0].fields.URL
    if (isAdmin) this.uri = this.powerbi[0].fields.URL
  }
}
</script>

<style lang="scss">
$top-panel-height: 48px;
$container-padding: 32px;
$fullHeight: calc(100vh - #{$top-panel-height} - #{$container-padding});

.bi {
  position: relative;
  width: 100%;
  height: $fullHeight
}

.bi__frame {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.bi__forbidden {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: $fullHeight;
}
</style>
